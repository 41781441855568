import cx from 'classnames'
import Img from 'gatsby-image'
// @ts-ignore
import { getFluidGatsbyImage, getFluidProps } from 'gatsby-source-sanity'
import React, { useState, useEffect } from 'react'

import imageUrlBuilder from '@sanity/image-url'
import sanityClient from '@sanity/client'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  // token: configOptions.accessToken,
  useCdn: false, // `false` if you want to ensure fresh data
})

export const loadLazyImage = function () {
  if ("IntersectionObserver" in window) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          if (entry.target.hasAttribute("data-src")) {
            entry.target.setAttribute(
                "src",
                entry.target.getAttribute("data-src") as string
            );
            observer.unobserve(entry.target);
          }
        }
      });
    });
    document.querySelectorAll(".lazy-image").forEach((imageElement) => {
      if (imageElement.getAttribute("is-observed") != "true" && imageElement.getAttribute("data-src") != null)
      {
        imageElement.setAttribute("is-observed", "true")
        observer.observe(imageElement);
      }
    });
  } else {
    const imgList = document.querySelectorAll(".lazy-image");
    Array.prototype.forEach.call(imgList, function (image) {
      image.setAttribute("src", image.getAttribute("data-src"));
    });
  }
};

export const Image = ({
  imageId,
  className,
  size = 1200,
  width,
  alt,
  src,
  onClick,
  raw,
  lazy,
  fromArticle,
  cls
}: {
  imageId?: string
  width?: number
  alt?: string
  size?: number
  onClick?: any
  raw?: {}
  className?: string
  src?: string
  lazy?: boolean
  fromArticle?: boolean
  cls?: string
}) => {
  const [mounted, setMounted] = useState(true) // FOR FUTURE
  const [loaded, setLoaded] = useState(false)
  const [visible, setVisible] = useState(false) // FOR FUTURE

  useEffect(() => {
    loadLazyImage();
  }, []);

  let fluidProps

  if (imageId && !/gif/.test(imageId)) {
    fluidProps = getFluidGatsbyImage(
      imageId,
      { maxWidth: width || 2400 },
      sanityConfig
    )
  }

  const builder = imageUrlBuilder(client)
  function urlFor(source) {
    return builder.image(source)
  }

  let imageSrc = imageId
  if (raw) {
    imageSrc = raw
  }

  return (
    <div onClick={onClick} className={cx('image__block', className)}>
      {fluidProps && (!raw || fromArticle) ? (
        <React.Fragment>
          <Img
            loading={!lazy ? 'lazy' : 'auto'}
            className={cx('hidden__image', cls, {
              'lazy-image': !lazy,
            })}
            fluid={fluidProps}
            alt={alt} />
        </React.Fragment>
      ) : (
        <img
          alt={alt}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          data-src={urlFor(imageSrc).width(size)}
          className={cx('x y object-fit block', {
            'lazy-image': !lazy,
            'is-loaded': loaded,
            'is-visible': visible,
          })}
          onLoad={() => {
            setLoaded(true)
          }}
        />
      )}
    </div>
  )
}
