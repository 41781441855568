import React, { useState, useEffect, useMemo, useContext } from 'react'

import { getVariantForFlag } from 'src/ampli/AmplitudeExperimentProvider'

import { RenderModules } from 'src/utils/renderModules'

export interface ExperimentProps {
  data: {
    experimentKey: string
    variantModules: any[]
    controlModules: any[]
  }
}

const render = (modules: any) => {
  return <>{modules.length > 0 && RenderModules(modules)}</>
}

export const Experiment = ({ data }: ExperimentProps) => {
  const variant = getVariantForFlag(data.experimentKey)

  if (variant === undefined) {
    return <></>
  } else if (variant === 'treatment') {
    console.log('about to render variant for', data.experimentKey)
    console.log(data.variantModules)
    return render(data.variantModules)
  } else {
    return render(data.controlModules)
  }
}

export default Experiment
